export default {
  // search
  FACET_PANEL_CLASS: 'page-sidebar',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',
  MAIN_CONTENT_CLASS: 'page-content',
  SHOW_RESULTS_CLASS: 'button button--primary',
  // fitmentSearch
  FACET_DIALOG_BUTTON_CLASS: 'button button--primary',
  GARAGE_ICON: '<rt-virtual template="icons/jeep" />',
  VEHICLE_WIDGET_HOME_CLASS: 'container',
  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button-secondary',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon submit search-submit',
};
